<template>
  <div id="home">
        <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date active" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date" href="01-may-2018">May</a></li>
                                <li><a class="archive-date" href="01-jun-2018">June</a></li>
                                <li><a class="archive-date" href="01-jul-2018">July</a></li>
                                <li><a class="archive-date" href="01-aug-2018">August</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #004</h2>
                        <p class="newsletter-subtitle">
                            "Good Greens”
                        </p>
                        <p class="newsletter-date">
                            (Apr 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            When this newsletter gets published it’ll be Easter Sunday… Hopefully you’re reading this and munching down on a Cadbury egg, thinking about all the quality food you’ve eaten recently which justifies it. “Ha, ha! Good one, Jamie.”
                        </p>
                        <p class="newsletter-paragraph">
                            Gone are the days where dinner consisted of ¾ of the plate being covered by veggies and there was no chance of dessert unless all the cabbage and peas were eaten. Growing up, this was certainly the rule in our household. It taught me that rewards are to be taken in moderation, and only if the good food was consumed first.
                        </p>
                        <p class="newsletter-paragraph">
                            There’s got to be a reason for this, right? We’re taught through school to eat your greens to be healthy, but how many of us remember why a stick of celery is better than a Cadbury egg? I decided to put this into a survey question and ask some of my close friends. The results were in, and it was time to see where the train of thought led with the importance of greens. Everyone commented on the sugar content of both.
                        </p>
                        <p class="newsletter-paragraph">
                            Before we dive into smart replies about how ‘chocolate is better, cause it’s tasty…’ Let’s look at some of my favourite greens and how they’ll benefit you every day. Not only with relation to sugar, but more importantly, the vitamins and minerals.
                        </p>
                        <p class="newsletter-paragraph">
                            <strong>My favourite greens.</strong>
                        </p>
                        <p class="newsletter-paragraph no-margin">
                            Baby Spinach:
                            <ul class="styled-list">
                                <li>
                                    High in <strong>Vitamin K</strong> (like most leafy greens), this is essential for injury recovery, and bone health.
                                </li>
                                <li>
                                    High in <strong>Manganese</strong>, this helps regulate the break-down of fats and carbs, and helps regulate blood sugar.
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph no-margin">
                            Broccoli:
                            <ul class="styled-list">
                                <li>
                                    High in <strong>Vitamin C</strong> , this plays an important role in immune health (we’ll get into this next month).
                                </li>
                                <li>
                                    High in <strong>Folate</strong>, which helps lower cholesterol, reduces the risk of cardiovascular disease and stroke.
                                </li>
                            </ul>
                        </p>
                        <p class="newsletter-paragraph">
                            <strong>Pro-tip: When choosing leafy greens, go for the darker ones. They’re higher in minerals and antioxidants.</strong>
                        </p>
                        <p class="newsletter-paragraph">
                            The point of this newsletter isn’t to stop you from eating chocolate, but to remember that it should be consumed in moderation and complimented with healthy foods.
                        </p>
                        <p class="newsletter-preview">
                            Next month sneak peak - immune health
                        </p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
.styled-list {
                list-style-type: circle;
                padding-top: 0;
                margin-top: 0;
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
            }
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
